import {Component, inject} from '@angular/core';
import {InputFieldComponent} from '../../atoms/input-field/input-field.component';
import {CustomButtonComponent} from '../../atoms/custom-button/custom-button.component';
import {FormBuilder, ReactiveFormsModule, Validators,} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {AuthService} from '../../../../data/auth-service';
import {ICONS} from '../../atoms/icons/icons.constants';

@Component({
  selector: 'login-form',
  standalone: true,
  imports: [
    CommonModule,
    InputFieldComponent,
    CustomButtonComponent,
    ReactiveFormsModule,
  ],
  template: `
    <div class="flex items-center justify-center h-screen">
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        class="bg-white w-full max-w-md flex flex-col items-center gap-4 p-8 border rounded-md shadow-lg"
      >
        <h1 class="text-3xl font-bold">Login</h1>

        <input-field
          label="Usuario"
          type="text"
          [path]="usernamePath"
          [control]="this.loginForm.controls['email']"
        />

        <input-field
          label="Contraseña"
          type="password"
          id="password"
          [path]="passwordPath"
          [control]="this.loginForm.controls['password']"
        />

        <custom-button [disabled]="isDisabled" label="Iniciar sesión" type="submit"/>

        @if (this.loginError) {
          <p class="text-red-500">Error al iniciar sesión. Por favor, verifica tus credenciales.</p>
        }
      </form>
    </div>

  `,
})
export class LoginFormComponent {
  usernamePath: string = ICONS.user;
  passwordPath: string = ICONS.eye;
  public loginError: boolean = false;
  public isLoading: boolean = false;

  private fb = inject(FormBuilder);
  public loginForm = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', Validators.required],
  });
  private router = inject(Router);
  private authService = inject(AuthService);

  get isDisabled(): boolean {
    return this.loginForm.invalid || this.isLoading;
  }

  onSubmit(): void {

    if (this.isLoading) return;

    if (this.loginForm.valid) {
      this.isLoading = true;
      console.log(this.loginForm.value);
      this.authService.login(this.loginForm.value.email!, this.loginForm.value.password!).subscribe(
        (result) => {
          this.isLoading = false;
          if (result) {
            this.router.navigateByUrl('/dashboard/home');
          } else {
            this.loginError = true;
            console.log('Error al iniciar sesión. Por favor, verifica tus credenciales.');
          }
        },
        (error) => {
          this.isLoading = false;
          this.loginError = true;
          console.error('Error de inicio de sesión:', error);
        }
      );
    }
  }
}
