import {Component} from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [],
  template: `
    <div>
      <h1>Acceso denegado</h1>
      <button (click)="return()" class=" bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Regresar
      </button>
    </div>
  `,
  styleUrl: './unauthorized.component.css'
})
export class UnauthorizedComponent {

  public return(): void {
    window.history.back();
  }
}
