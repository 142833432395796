import { Component } from '@angular/core';
import { LoginFormComponent } from '../../common/ui/organisms/login-form/login-form.component';

@Component({
  selector: 'login',
  standalone: true,
  imports: [LoginFormComponent],
  template: `
    <div class="flex flex-col items-center justify-center bg-white">
      <login-form />
    </div>
  `,
  styleUrl: './login.component.css',
})
export class LoginComponent {}
