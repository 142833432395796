import {Component, inject} from '@angular/core';
import {InputFieldComponent} from '../../atoms/input-field/input-field.component';
import {AbstractControl, FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {CustomButtonComponent} from '../../atoms/custom-button/custom-button.component';
import {AuthService} from '../../../../data/auth-service';

@Component({
  selector: 'reset-password-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
    CustomButtonComponent
  ],
  template: `
    <div class="flex flex-col items-center justify-center bg-white">
      <form
        class="bg-white w-full max-w-md flex flex-col items-center gap-4 p-8 border rounded-md shadow-lg"
        [formGroup]="passwordResetForm"
        (ngSubmit)="onSubmit()"
      >
        <h1 class="text-2xl font-bold text-gray-900">Cambiar Contraseña</h1>

        <input-field
          type="password"
          [label]="'Contraseña Actual'"
          [control]="passwordResetForm.controls['oldPassword']"
        />

        <input-field
          type="password"
          [label]="'Nueva Contraseña'"
          [control]="passwordResetForm.controls['newPassword']"
        />


        <input-field
          type="password"
          [label]="'Confirmar Contraseña'"
          [control]="passwordResetForm.controls['confirmNewPassword']"
        />
        <custom-button
          label="Cambiar"
          type="submit"
        />
        @if (passwordResetForm.errors?.['passwordMissMatch'] && passwordResetForm.dirty) {
          <p class="text-red-500">Las contraseñas no coinciden</p>
        }
        @if (passwordResetForm.controls.newPassword.errors?.['minlength'] && passwordResetForm.dirty) {
          <p class="text-red-500">La contraseña debe tener al menos 8 caracteres</p>
        }
      </form>
    </div>
  `,
  styleUrl: './reset-password-form.component.css'
})
export class ResetPasswordFormComponent {

  private fb = inject(FormBuilder);
  private authService = inject(AuthService);

  public passwordResetForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(8)]],
    },
    {validators: this.passwordMatchValidator}
  )

  public onSubmit(): void {
    if (this.passwordResetForm.valid) {
      console.log('Contraseña cambiada');
      console.log(this.passwordResetForm.value);
      this.authService.changePassword(
        this.passwordResetForm.value.oldPassword!,
        this.passwordResetForm.value.newPassword!,
        this.passwordResetForm.value.confirmNewPassword!
      );
    } else {
      console.log('Contraseña no cambiada');
    }
  }

  private passwordMatchValidator(control: AbstractControl) {
    const newPassword = control.get('newPassword')?.value;
    const confirmedNewPassword = control.get('confirmNewPassword')?.value;
    return newPassword === confirmedNewPassword ? null : {passwordMissMatch: true};
  }
}
