import { Component } from '@angular/core';
import {ResetPasswordFormComponent} from '../../common/ui/organisms/reset-password-form/reset-password-form.component';
import {CustomButtonComponent} from '../../common/ui/atoms/custom-button/custom-button.component';
import {InputFieldComponent} from '../../common/ui/atoms/input-field/input-field.component';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'reset-password',
  standalone: true,
  imports: [
    ResetPasswordFormComponent,
    CustomButtonComponent,
    InputFieldComponent,
    ReactiveFormsModule
  ],
  template: `
    <div class="flex items-center justify-center h-screen">
        <reset-password-form/>
    </div>
  `,
  styleUrl: './reset-password.component.css'
})
export class ResetPasswordComponent {

}
