import {Component, Input, input, TemplateRef} from '@angular/core';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {ModalComponent} from '../../atoms/modal/modal.component';
import {NgTemplateOutlet} from '@angular/common';
import {ICONS} from '../../atoms/icons/icons.constants';

@Component({
  selector: 'app-item-details',
  standalone: true,
  imports: [
    CdkDrag,
    ModalComponent,
    NgTemplateOutlet
  ],
  template: `
    @for (item of items; track item.id; ) {
      <div cdkDrag class="relative mt-4">
        @if (selectedItem?.id === item.id) {
          <app-modal (close)="closeModal()">
            <div class="p-4">
              <h4 class="font-bold mb-4">Detalles del Pedido:</h4>
              @for (product of item.productList; track product.productId; ) {
                <div class="mb-4 p-2 border rounded">
                  <p><strong>Producto:</strong> {{ product.name }}</p>
                  <p><strong>Cantidad:</strong> {{ product.quantity }}</p>
                  @if (product.extras.length > 0) {
                    @for (extra of product.extras; track extra.id) {
                      <p>{{ extra.name }}</p>
                      <p><strong>Precio:</strong> {{ product.currency }} {{ product.price }}</p>
                    }
                  }
                </div>
              }
              <div class="mt-4">
                <p><strong>Orden:</strong> {{ item.orderCode }}</p>
                <p><strong>Cliente:</strong> {{ item.contactInfo?.name }}</p>
                <p><strong>Teléfono:</strong> {{ item.contactInfo?.phoneNumber }}</p>
                <p><strong>Tipo de entrega:</strong> {{ item.deliveryMode }}</p>
                <p><strong>Dirección:</strong> {{ item.shippingAddress?.address }},
                  {{ item.shippingAddress?.city }}, {{ item.shippingAddress?.municipality }}</p>

                @for (event of item.eventHistory; track $index; ) {
                  <p><strong>{{ statusAdapter(event.type) }}:</strong> {{ event.date }}</p>
                }
              </div>
            </div>
          </app-modal>
        }
        <button (click)="showItemInfo(item)" class="focus:outline-none absolute right-2 top-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="w-5 h-4 text-black"
          >
            <path [attr.d]="eyeIcon"/>
          </svg>
        </button>
        <ng-container
          *ngTemplateOutlet="itemTemplate(); context: { $implicit: item }"
        >
        </ng-container>
      </div>
    }
  `,
  styleUrl: './item-details.component.css'
})
export class ItemDetailsComponent {

  @Input() items: any[] = [];
  public itemTemplate = input<TemplateRef<any> | null>(null);

  public eyeIcon = ICONS.eye;
  public selectedItem: any = null;

  showItemInfo(item: any) {
    this.selectedItem = item;
  }

  closeModal() {
    this.selectedItem = null;
  }

  public statusAdapter(eventType: string): string {
    switch (eventType) {
      case 'created':
        return 'Creada';
      case 'initPreparation':
        return 'En preparación';
      case 'readyToDeliver':
        return 'Preparada para entrega';
      case 'inRoute':
        return 'En ruta';
      case 'completed':
        return 'Entregada';
      default:
        return '';
    }
  }
}
