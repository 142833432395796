import {Component, inject, Input, input, output, TemplateRef} from '@angular/core';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {ItemDetailsComponent} from '../../organisms/item-details/item-details.component';
import {AuthService} from '../../../../data/auth-service';
import {RolesEnum} from '../../../../shared/interfaces/roles.enum';

@Component({
  selector: 'drag-handle-component',
  standalone: true,
  template: `
    <div
      class="p-4 bg-gray-100 rounded overflow-y-auto max-h-[400px] lg:max-h-[800px]"
      cdkDropList
      [id]="listId()"
      [cdkDropListData]="items()"
      [cdkDropListConnectedTo]="connectedTo()"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListDisabled]="dragDisabled()"
    >
      <div class="flex justify-between content-center mb-4">
        <h3 class="font-bold text-lg lg:text-base">{{ title() }}</h3>
      </div>

      <app-item-details
        [items]="items()"
        [itemTemplate]="itemTemplate()"
      />
    </div>
  `,
  styleUrl: './drag-handle-component.component.css',
  imports: [
    DragDropModule,
    ItemDetailsComponent,
  ],
})
export class DragHandleComponentComponent {

  public items : any = input<any>([])
  public connectedTo = input<string[]>([]);
  public title = input.required<string>();
  public listId = input.required<string>();
  public itemTemplate = input<TemplateRef<any> | null>(null);
  public dragDisabled = input<boolean>(false);

  public itemMoved = output<{
    item: any;
    targetedListId: string;
    newIndex: number;
    previousListId: string;
  }>();

  public onDrop = output<string>();

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const movedItem = event.previousContainer.data[event.previousIndex];
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.itemMoved.emit({
        item: movedItem,
        targetedListId: event.container.id,
        newIndex: event.currentIndex,
        previousListId: event.previousContainer.id,
      });
    }
  }
}
