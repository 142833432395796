import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [],
  template: `
    <p>
      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      (click)="changePassword()"
      >
        Cambiar contraseña
      </button>
    </p>
  `,
  styleUrl: './profile.component.css'
})
export class ProfileComponent {

  private router = inject(Router)

  protected changePassword() {
    this.router.navigateByUrl("/reset");
  }

}
