import {Component, inject} from '@angular/core';
import {
  DragHandleComponentComponent
} from '../../common/ui/molecules/drag-handle-component/drag-handle-component.component';
import {MainLayoutComponent} from '../../common/ui/templates/main-layout/main-layout.component';
import {OrdersService} from '../../data/orders.service';
import {OrderCardComponent} from '../../common/ui/molecules/order-card/order-card.component';
import {OrderBoardColumnComponent} from '../../common/ui/organisms/order-board-column/order-board-column.component';



@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [MainLayoutComponent, OrderBoardColumnComponent],
  template: `
    <main-layout>
        <order-board-column/>
    </main-layout>

  `,
})
export class HomeComponent {
  public readonly ordersService = inject(OrdersService);
}
