import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../../data/auth-service';
import { inject } from '@angular/core';
import { RolesEnum } from '../../../shared/interfaces/roles.enum';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const allowedRoles = route.data?.['roles'] || [RolesEnum.ADMIN];

  console.log(authService.userRole());
  if (allowedRoles.includes(authService.userRole())) {
    return true;
  }

  router.navigateByUrl("/unauthorized");
  return false;
};
