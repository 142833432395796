import {Routes} from '@angular/router';
import {LoginComponent} from './features/login/login.component';
import {HomeComponent} from './features/home/home.component';
import {ResetPasswordComponent} from './features/reset-password/reset-password.component';
import {isAuthenticatedGuard} from './core/guards/authentication/is-authenticated.guard';
import {isNotAuthenticatedGuard} from './core/guards/authentication/is-not-authenticated.guard';
import {ProfileComponent} from './features/profile/profile.component';
import {roleGuard} from './core/guards/role/role.guard';
import {UnauthorizedComponent} from './features/unauthorized/unauthorized.component';
import {RolesEnum} from './shared/interfaces/roles.enum';

export const routes: Routes = [

  {
    path: 'auth',
    canActivate: [isNotAuthenticatedGuard],
    children: [
      {path: 'login', component: LoginComponent},
    ]
  },

  {
    path: 'dashboard',
    canActivate: [isAuthenticatedGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [roleGuard],
        data: {roles: [RolesEnum.ADMIN, RolesEnum.CASHIER, RolesEnum.MANAGER]}
      },
      {
        path: 'calendar',
        canActivate: [roleGuard],
        loadComponent: () => import('./features/orders-calendar-report/orders-calendar-report.component').then(c => c.OrdersCalendarReportComponent),
        data: {roles: [RolesEnum.ADMIN, RolesEnum.MANAGER]}
      },
      {
        path: 'calendar/orders/:month/:day',
        canActivate: [roleGuard],
        loadComponent: () => import('./features/orders-of-day/orders-of-day.component').then(c => c.OrdersOfDayComponent),
        data: {roles: [RolesEnum.ADMIN, RolesEnum.MANAGER]}
      },
      {
        path: 'stores',
        canActivate: [roleGuard],
        loadComponent: () => import('./features/stores/stores.component').then(c => c.StoresComponent),
        data: {roles: [RolesEnum.ADMIN]}
      },
      {
        path: 'products',
        canActivate: [roleGuard],
        loadComponent: () => import('./features/products/products.component').then(c => c.ProductsComponent),
        data: {roles: [RolesEnum.ADMIN, RolesEnum.MANAGER]}
      },
      {
        path: 'stock',
        canActivate: [roleGuard],
        loadComponent: () => import('./features/stock/stock.component').then(c => c.StockComponent),
        data: {roles: [RolesEnum.ADMIN, RolesEnum.MANAGER]}
      }
    ]
  },

  {
    path: 'profile',
    canActivate: [isAuthenticatedGuard],
    component: ProfileComponent
  },
  {path: 'reset', component: ResetPasswordComponent},

  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  {path: '**', redirectTo: '/auth/login', pathMatch: 'full'},
];
