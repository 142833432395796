import {Component, effect, inject} from '@angular/core';
import {DragHandleComponentComponent} from '../../molecules/drag-handle-component/drag-handle-component.component';
import {OrdersService} from '../../../../data/orders.service';
import {OrderCardComponent} from '../../molecules/order-card/order-card.component';
import {OrderBoardDTO} from '../../../../core/orders';
import {SpinnerComponent} from '../../atoms/spinner/spinner.component';
import {AuthService} from '../../../../data/auth-service';
import {RolesEnum} from '../../../../shared/interfaces/roles.enum';

@Component({
  selector: 'order-board-column',
  standalone: true,
  imports: [DragHandleComponentComponent, OrderCardComponent, SpinnerComponent],
  template: `
    <ng-container>
      @if (ordersService.orders().isLoading) {
        <div class="flex justify-center h-full">
          <app-spinner color="accent" size="lg"/>
        </div>
      } @else {
        <div class="flex flex-col lg:flex-row justify-start lg:justify-center p-4 lg:p-12
                    gap-4 lg:gap-8 w-full h-full overflow-x-auto">
          <div class="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap w-full gap-4 lg:gap-8">
            <drag-handle-component
              [items]="created"
              title="1. Creada"
              [itemTemplate]="myItemTemplate"
              listId="Created"
              [connectedTo]="['initPreparation']"
              (itemMoved)="handleItemMoved($event)"
              class="w-full md:w-[calc(50%-1rem)] lg:w-64"
              [dragDisabled]="this.authService.userRole() !== RolesEnum.ADMIN && this.authService.userRole() !== RolesEnum.MANAGER"
            />

            <drag-handle-component
              [items]="initPreparation"
              title="2. En Preperación"
              [itemTemplate]="myItemTemplate"
              listId="initPreparation"
              [connectedTo]="['readyToDeliver']"
              (itemMoved)="handleItemMoved($event)"
              class="w-full md:w-[calc(50%-1rem)] lg:w-64"
              [dragDisabled]="this.authService.userRole() !== RolesEnum.ADMIN && this.authService.userRole() !== RolesEnum.MANAGER"
            />

            <drag-handle-component
              [items]="readyToDeliver"
              title="3. Lista Para Envio"
              [itemTemplate]="myItemTemplate"
              listId="readyToDeliver"
              [connectedTo]="['inRoute']"
              (itemMoved)="handleItemMoved($event)"
              class="w-full md:w-[calc(50%-1rem)] lg:w-64"
              [dragDisabled]="this.authService.userRole() !== RolesEnum.ADMIN && this.authService.userRole() !== RolesEnum.MANAGER"
            />

            <drag-handle-component
              [items]="inRoute"
              title="4. En Ruta"
              [itemTemplate]="myItemTemplate"
              listId="inRoute"
              [connectedTo]="['completed']"
              (itemMoved)="handleItemMoved($event)"
              class="w-full md:w-[calc(50%-1rem)] lg:w-64"
              [dragDisabled]="this.authService.userRole() !== RolesEnum.ADMIN && this.authService.userRole() !== RolesEnum.MANAGER"
            />

            <drag-handle-component
              [items]="completed"
              title="5. Completada"
              listId="completed"
              [itemTemplate]="myItemTemplate"
              [connectedTo]="[]"
              (itemMoved)="handleItemMoved($event)"
              class="w-full md:w-[calc(50%-1rem)] lg:w-64"
              [dragDisabled]="this.authService.userRole() !== RolesEnum.ADMIN && this.authService.userRole() !== RolesEnum.MANAGER"
            />
          </div>
        </div>
      }
    </ng-container>

    <ng-template #myItemTemplate let-item>
      <app-order-card [order]="item"></app-order-card>
    </ng-template>
  `,
  styleUrl: './order-board-column.component.css',
})
export class OrderBoardColumnComponent {

  protected readonly ordersService = inject(OrdersService);
  protected authService = inject(AuthService);


  public created: OrderBoardDTO[] = [];
  public initPreparation: OrderBoardDTO[] = [];
  public readyToDeliver: OrderBoardDTO[] = [];
  public inRoute: OrderBoardDTO[] = [];
  public completed: OrderBoardDTO[] = [];

  constructor() {
    effect(() => {

      console.log("pasaron 60 segundos");
      console.log(this.ordersService.orders());
      this.created = this.ordersService.orders().Created;
      this.initPreparation = this.ordersService.orders().InitPreparation;
      this.readyToDeliver = this.ordersService.orders().ReadyToDeliver;
      this.inRoute = this.ordersService.orders().InRoute;
      this.completed = this.ordersService.orders().Completed;
    });
  }

  async handleItemMoved(event: {
    item: OrderBoardDTO;
    targetedListId: string;
    newIndex: number;
    previousListId: string;
  }) {
    try {
      await this.ordersService.updateOrderState(event.item, event.targetedListId);
    } catch (error) {

      console.log(event.targetedListId);

      let targetedList: OrderBoardDTO[] = this.getListByListId(event.targetedListId);
      let previousList: OrderBoardDTO[] = this.getListByListId(event.previousListId);
      const updatedTargetedList = targetedList.filter((item) => item.id !== event.item.id);
      const updatedPreviousList = [...previousList, event.item];
      this.updateList(event.targetedListId, updatedTargetedList);
      this.updateList(event.previousListId, updatedPreviousList);
    }
  }

  private getListByListId(listId: string): OrderBoardDTO[] {
    const lists: { [key: string]: OrderBoardDTO[] } = {
      Created: this.created,
      InitPreparation: this.initPreparation,
      ReadyToDeliver: this.readyToDeliver,
      InRoute: this.inRoute,
      Completed: this.completed
    };
    return lists[listId];
  }

  private updateList(listId: string, newList: OrderBoardDTO[]) {
    switch (listId) {
      case 'Created': {
        this.created = newList;
        break;
      }
      case 'InitPreparation': {
        this.initPreparation = newList;
        break;
      }

      case 'ReadyToDeliver': {
        this.readyToDeliver = newList;
        break;
      }

      case 'InRoute': {
        this.inRoute = newList;
        break;
      }

      case 'Completed': {
        this.completed = newList;
        break;
      }
    }
  }

  protected readonly RolesEnum = RolesEnum;
}
