import {DragDropModule} from '@angular/cdk/drag-drop';
import {Component, computed, effect, inject} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {Router, RouterOutlet} from '@angular/router';
import {BranchService} from './data/branch.service';
import {AuthService} from './data/auth-service';
import {AuthStatus} from './shared/interfaces/auth-status.enum';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ReactiveFormsModule,
    DragDropModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [BranchService]
})
export class AppComponent {
  title = 'pavlova-manager';

  private router = inject(Router);
  private authService = inject(AuthService);

  public finishedAuthCheck = computed<boolean>(() => {
    if (this.authService.authStatus() === AuthStatus.checking) {
      return false;
    }
    return true;
  })

  public authStatusChangeEffed = effect(() => {

      if (this.authService.authStatus() === AuthStatus.checking) {
        return; // No navega mientras está en estado checking
      }

    console.log('authstatus', this.authService.authStatus());
    switch (this.authService.authStatus()) {
      case AuthStatus.checking:
        return;
      case AuthStatus.authenticated:
        this.router.navigateByUrl('/dashboard/home');
        return;
      case AuthStatus.notAuthenticated:
        this.router.navigateByUrl('/auth/login');
        return;
    }
  })
}
