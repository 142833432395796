import {computed, inject, Injectable, OnDestroy, signal} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OrderBoardDTO} from '../core/orders';
import {catchError, forkJoin, interval, map, Subscription, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrdersService implements OnDestroy {

  private readonly API_URL: string = 'https://dev.api.pavlova.pulpo.group/api';

  #orders = signal<{
    isLoading: boolean;
    Created: OrderBoardDTO[];
    InitPreparation: OrderBoardDTO[];
    ReadyToDeliver: OrderBoardDTO[];
    InRoute: OrderBoardDTO[];
    Completed: OrderBoardDTO[];
  }>({
    isLoading: true,
    Created: [],
    InitPreparation: [],
    ReadyToDeliver: [],
    InRoute: [],
    Completed: []
  });
  public orders = computed(this.#orders);

  private http = inject(HttpClient);
  private updateSubscription?: Subscription;


  private loadAllOrders() {
    forkJoin({
      Created: this.fetchOrdersByState('Created'),
      InitPreparation: this.fetchOrdersByState('InPreparation'),
      ReadyToDeliver: this.fetchOrdersByState('ReadyToDeliver'),
      InRoute: this.fetchOrdersByState('InRoute'),
      Completed: this.fetchOrdersByState('Completed'),
    }).subscribe((result) => {
      this.#orders.set({
        isLoading: false,

        Created: result.Created,
        InitPreparation: result.InitPreparation,
        ReadyToDeliver: result.ReadyToDeliver,
        InRoute: result.InRoute,
        Completed: result.Completed
      });
    });
  }

  private startPolling() {
    this.updateSubscription = interval(60000)
      .subscribe(() => this.loadAllOrders());
  }

  constructor() {
    this.loadAllOrders();
    this.startPolling()
  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
  }

  fetchOrdersByState(state: string) {
    return this.http
      .post<{ content: OrderBoardDTO[] }>(this.API_URL + '/orders/search', {
        query: {
          clientId: '',
          date: null,
          deliveryDate: '2024-10-30T00:30:29.292982Z',
          status: state,
          storeId: null,
        },
        page: {page: 1, size: 100},
      })
      .pipe(map((res) => res.content));
  }

  updateOrderState(order: OrderBoardDTO, newState: string) {
    const payload = {
      event_type: newState,
      payload: {},
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${this.API_URL}/orders/${order.id}/dispatch-event`, payload, {headers}).pipe(
      catchError((error) => {
        console.error("Error updating order:", error);
        return throwError(() => new Error("Error updating order"));
      })
    ).toPromise();
  }
}
