
@if(!finishedAuthCheck()){
  <h1>
    Loading
  </h1>
}

@if(finishedAuthCheck()){
<router-outlet />
}



