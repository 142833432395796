import {Component, input} from '@angular/core';
import {OrderBoardDTO} from '../../../../core/orders';
import {NgForOf} from '@angular/common';

@Component({
  selector: 'app-order-card',
  standalone: true,
  imports: [
    NgForOf
  ],
  template: `
    <div class="card bg-white shadow-md rounded-lg p-4">
      <div class="mb-4">
        <span class="font-bold">Código: </span> <span>{{ order().orderCode }}</span>
        <br>
        <span class="font-bold">Tipo de Entrega: </span> <span>{{ order().deliveryMode }}</span>
      </div>
      <div>
        <ul>
          <li *ngFor="let item of order().productList" class="flex justify-between">
            <span>{{ item.name }}</span>
            <span>{{ item.quantity }}</span>
          </li>
        </ul>
      </div>
    </div>
  `,
  styles: []
})
export class OrderCardComponent {
  public order = input.required<OrderBoardDTO>();
}
